<template>
    <vs-popup
        :title="title"
        :active.sync="show"
        class="vs-con-loading__container"
        id="loading-container_referral_modal"
    >
        <vs-checkbox
            type="email"
            v-model="form.broker_contacted"
            label="Account Email"
            class="mb-3 w-full"
            :danger="errors.email"
            :danger-text="errors.email"
            >Contacted</vs-checkbox
        >

        <vs-divider />
        <div class="flex justify-end">
            <vs-button
                :disabled="!validForm"
                v-on:click="submit"
                color="primary"
                class="mr-3"
                >{{ buttonText }}
            </vs-button>
            <vs-button v-on:click="$emit('close')" type="flat"
                >Cancel</vs-button
            >
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'

export default {
    name: 'EditModal',
    mixins: [],
    props: ['showData', 'logData'],
    watch: {
        logData: {
            immediate: true,
            handler(value) {
                if (value) {
                    this.form.id = value.id
                    this.form.broker_contacted = value.broker_contacted
                } else {
                    this.clearForm()
                }
            },
        },
    },
    computed: {
        show: {
            get() {
                return this.showData
            },
            set(value) {
                this.clearForm()
                this.clearErrors()
                this.$emit('close')
            },
        },
        title() {
            return this.form.id ? 'Edit' : 'Create'
        },
        buttonText() {
            return this.form.id ? 'Update' : 'Save'
        },
        validForm() {
            return true
        },
    },
    data() {
        return {
            form: {
                id: null,
                broker_contacted: null,
            },
            errors: {
                broker_contacted: null,
            },
        }
    },
    methods: {
        submit() {
            if (!this.validForm) return
            this.patch()
        },
        clearForm() {
            this.form.id = null
            this.form.email = null
        },
        handleErrors(errors) {
            if (errors.email) this.errors.email = errors.email[0]
        },
        clearErrors() {
            this.errors.email = null
        },
        patch() {
            this.clearErrors()
            this.$vs.loading({
                color: 'primary',
                container: '#loading-container_referral_modal',
                scale: 0.45,
            })

            http.patch('advice_list', this.form)
                .then((response) => {
                    this.$vs.loading.close(
                        '#loading-container_referral_modal > .con-vs-loading'
                    )
                    this.$emit('update')
                    this.$emit('close')
                    this.clearForm()
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Lead Updated',
                        color: 'success',
                        icon: 'feather',
                        iconPack: 'icon-edit',
                        position: 'top-center',
                    })
                })
                .catch((error) => {
                    this.handleErrors(error.response.data)
                    this.$vs.loading.close(
                        '#loading-container_referral_modal > .con-vs-loading'
                    )
                })
        },
    },
}
</script>
