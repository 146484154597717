<template>
    <vs-table
        :onlyClickCheckbox="true"
        ref="table"
        v-model="selected"
        :sst="true"
        search
        :data="logs"
        @sort="handleSort"
        @search="search"
    >
        <div slot="header" class="flex items-center flex-grow justify-end">
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>

            <!-- Filter -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2">Filter - {{ filterLabel }}</span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('filter', 'all')">
                        <span>All</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('filter', 'to_contact')">
                        <span>To Contact</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('filter', 'contacted')">
                        <span>Contacted</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th>Name</vs-th>
            <vs-th sort-key="date_request">Date Requested</vs-th>
            <vs-th>Advice Required</vs-th>
            <vs-th>Contact Details</vs-th>
            <vs-th>Status</vs-th>
            <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr :data="log" :key="index" v-for="(log, index) in data">
                    <vs-td>
                        <p v-if="log.user && log.user.personaldetails">
                            {{ log.user.personaldetails.full_name }}
                        </p>
                    </vs-td>
                    <vs-td>
                        {{ formatDateAndTime(log.date_request) }}
                    </vs-td>
                    <vs-td>
                        <p v-if="log.life_insurance">Life Insurance</p>
                        <p v-if="log.life_insurance_review">
                            Life Insurance Review
                        </p>
                    </vs-td>
                    <vs-td>
                        <p
                            v-if="
                                log.user && log.user.personaldetails &&
                                log.user.personaldetails.contact_email
                            "
                        >
                            {{ log.user.personaldetails.contact_email }}
                        </p>
                        <p v-if="log.user && log.user.personaldetails.phone">
                            {{ log.user.personaldetails.phone }}
                        </p>
                    </vs-td>

                    <vs-td>
                        <vs-chip v-if="!log.broker_contacted" color="secondary"
                            >To Contact</vs-chip
                        >
                        <vs-chip v-else color="success">Contacted</vs-chip>
                    </vs-td>
                    <vs-td>
                        <div class="flex justify-end row-hidden-elements">
                            <vx-tooltip text="Edit" color="primary">
                                <vs-button
                                    type="flat"
                                    icon-pack="feather"
                                    icon="icon-edit"
                                    @click="$emit('edit', log)"
                                ></vs-button>
                            </vx-tooltip>
                        </div>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/textFormat'

export default {
    name: 'AdviceRequestsTable',
    mixins: [textFormat],
    props: ['data', 'itemsPerPage', 'view'],
    computed: {
        logs() {
            return this.data.data
        },
        filterLabel() {
            if (this.view === 'all') return 'All'
            if (this.view === 'code_clicked') return 'Code Clicked'
            if (this.view === 'code_not_sent') return 'Invitation Not Sent'
            if (this.view === 'code_sent') return 'Invitation Sent'
            if (this.view === 'code_used') return 'Account Created'
            if (this.view === 'code_completed') return 'Will Created'
            return ''
        },
    },
    data() {
        return {
            statusSortKey: 'to_contact',
            selected: [],
            searchQuery: '',
            status: 'All',
            selectMultiple: false,
            filter: 'all',
        }
    },
    methods: {
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        toggleSelect() {
            this.selected = []
            this.selectMultiple = !this.selectMultiple
        },
        createMirrorWill(log) {
            this.$root.$emit('createMirrorWill', log.email)
            window.scrollTo(0, 0)
        },
        handleSort(key, value) {
            this.$emit('sort', [key, value])
        },
    },
}
</script>

<style>
.row-hidden-elements {
    visibility: hidden;
}
.vs-table tr:hover .row-hidden-elements {
    visibility: visible;
}
</style>
