<template>
    <div class="">
        <div
            id="data-list-list-view"
            class="data-list-container"
            v-if="adviceRequests"
        >
            <AdviceRequestsTable
                :data="adviceRequests"
                :itemsPerPage="itemsPerPage"
                @itemsPerPage="setItemsPerPage"
                @search="search"
                @filter="setFilter"
                @edit="setEdit"
                @sort="setSort"
                :view="filter"
            ></AdviceRequestsTable>
            <div class="paginator-container">
                <vs-pagination
                    description
                    goto
                    descriptionTitle="Advice Requests
"
                    :sizeArray="adviceRequests.count"
                    :description-items="descriptionItems"
                    :total="adviceRequests.number_of_pages"
                    :max-items="itemsPerPage"
                    :max="itemsPerPage"
                    v-model="pageNumberComputed"
                    @changeMaxItems="setItemsPerPage"
                ></vs-pagination>
            </div>
        </div>
        <EditModal
            :show-data="show.editModal"
            :log-data="selected"
            @close="closeEditModal"
            @update="getPage"
        />
    </div>
</template>

<script>
import AdviceRequestsTable from './Table'
import { http } from '@/services'
import EditModal from '@/views/main/AdviceRequests/EditModal'

export default {
    name: 'AdviceRequests',
    components: {
        EditModal,
        AdviceRequestsTable,
    },
    created() {
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'advice_requests',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {
        pageNumberComputed: {
            set(value) {
                this.pageNumber = value
                this.getPage()
            },
            get() {
                return this.pageNumber
            },
        },
    },
    data() {
        return {
            adviceRequests: null,
            pageNumber: 1,
            itemsPerPage: 10,
            selected: null,
            sort: null,
            queriedItems: 20,
            searchQuery: null,
            filter: 'all',
            descriptionItems: [5, 10, 20],
            show: {
                editModal: false,
            },
        }
    },
    methods: {
        setSort(value) {
            this.sort = value
            this.getPage()
        },

        setItemsPerPage(value) {
            this.itemsPerPage = this.descriptionItems[value]
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        setView(value) {
            this.searchQuery = null
            this.view = value
            this.getPage()
        },
        getPage() {
            http.get('advice_list', {
                params: {
                    filter: this.filter,
                    items_per_page: this.itemsPerPage,
                    page_number: this.pageNumber,
                    query: this.searchQuery,
                    view: this.view,
                    sort: this.sort,
                },
            })
                .then((response) => {
                    this.adviceRequests = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.searchQuery = value
            this.getPage()
        },
        setFilter(value) {
            this.filter = value
            this.getPage()
        },
        setEdit(value) {
            this.selected = value
            this.show.editModal = true
        },
        setNew() {
            this.selected = null
            this.show.editModal = true
        },
        closeEditModal() {
            this.show.editModal = false
            this.selected = null
        },
    },
}
</script>
